<template>
  <div class="news-detail">
    <div class="header-title">新闻动态</div>
    <div class="new-title">{{ detail.title }}</div>
    <div class="times">{{ detail.create_time }}</div>
    <div style="text-align: center;" v-html="detail.content"></div>
    <div class="more">
      <div class="btn" @click="lookMore">查看更多</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      detail: {}
    }
  },
  created () {
    this.detail = this.$route.query
  },

  methods: {
    lookMore () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-card-meta-title {
    font-weight: 700;
    font-size: 18px;
    color: #333333;
  }
  .ant-card-body{
    padding: 0;
  }
}
</style>

<style lang='scss' scoped>
.news-detail{
  width: 980px;
  margin: 0 auto;
  margin-top: 68px;
  .header-title{
    width: 100%;
    padding-top: 39px;
    font-size: 32px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: center;
    color: #333333;
    line-height: 38px;
  }
  .new-title{
    font-size: 24px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: center;
    color: #333333;
    line-height: 28px;
    margin: 67px 0 21px 0;
  }
  .times{
    margin-bottom: 50px;
    text-align: center;
    color: #666666;
  }
  .more{
    margin: 91px 0 81px 0;
    .btn{
      margin: 0 auto;
      width: 142px;
      height: 42px;
      border: 0.5px solid #333333;
      color: #333333;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      line-height: 42px;
      cursor: pointer;
    }
  }
}

</style>
